export class JOSEError extends Error {
  static get code() {
    return 'ERR_JOSE_GENERIC';
  }
  constructor(message) {
    var _a;
    super(message);
    this.code = 'ERR_JOSE_GENERIC';
    this.name = this.constructor.name;
    (_a = Error.captureStackTrace) === null || _a === void 0 ? void 0 : _a.call(Error, this, this.constructor);
  }
}
export class JWTClaimValidationFailed extends JOSEError {
  static get code() {
    return 'ERR_JWT_CLAIM_VALIDATION_FAILED';
  }
  constructor(message, claim = 'unspecified', reason = 'unspecified') {
    super(message);
    this.code = 'ERR_JWT_CLAIM_VALIDATION_FAILED';
    this.claim = claim;
    this.reason = reason;
  }
}
export class JWTExpired extends JOSEError {
  static get code() {
    return 'ERR_JWT_EXPIRED';
  }
  constructor(message, claim = 'unspecified', reason = 'unspecified') {
    super(message);
    this.code = 'ERR_JWT_EXPIRED';
    this.claim = claim;
    this.reason = reason;
  }
}
export class JOSEAlgNotAllowed extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JOSE_ALG_NOT_ALLOWED';
  }
  static get code() {
    return 'ERR_JOSE_ALG_NOT_ALLOWED';
  }
}
export class JOSENotSupported extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JOSE_NOT_SUPPORTED';
  }
  static get code() {
    return 'ERR_JOSE_NOT_SUPPORTED';
  }
}
export class JWEDecryptionFailed extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JWE_DECRYPTION_FAILED';
    this.message = 'decryption operation failed';
  }
  static get code() {
    return 'ERR_JWE_DECRYPTION_FAILED';
  }
}
export class JWEDecompressionFailed extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JWE_DECOMPRESSION_FAILED';
    this.message = 'decompression operation failed';
  }
  static get code() {
    return 'ERR_JWE_DECOMPRESSION_FAILED';
  }
}
export class JWEInvalid extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JWE_INVALID';
  }
  static get code() {
    return 'ERR_JWE_INVALID';
  }
}
export class JWSInvalid extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JWS_INVALID';
  }
  static get code() {
    return 'ERR_JWS_INVALID';
  }
}
export class JWTInvalid extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JWT_INVALID';
  }
  static get code() {
    return 'ERR_JWT_INVALID';
  }
}
export class JWKInvalid extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JWK_INVALID';
  }
  static get code() {
    return 'ERR_JWK_INVALID';
  }
}
export class JWKSInvalid extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JWKS_INVALID';
  }
  static get code() {
    return 'ERR_JWKS_INVALID';
  }
}
export class JWKSNoMatchingKey extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JWKS_NO_MATCHING_KEY';
    this.message = 'no applicable key found in the JSON Web Key Set';
  }
  static get code() {
    return 'ERR_JWKS_NO_MATCHING_KEY';
  }
}
export class JWKSMultipleMatchingKeys extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JWKS_MULTIPLE_MATCHING_KEYS';
    this.message = 'multiple matching keys found in the JSON Web Key Set';
  }
  static get code() {
    return 'ERR_JWKS_MULTIPLE_MATCHING_KEYS';
  }
}
Symbol.asyncIterator;
export class JWKSTimeout extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JWKS_TIMEOUT';
    this.message = 'request timed out';
  }
  static get code() {
    return 'ERR_JWKS_TIMEOUT';
  }
}
export class JWSSignatureVerificationFailed extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JWS_SIGNATURE_VERIFICATION_FAILED';
    this.message = 'signature verification failed';
  }
  static get code() {
    return 'ERR_JWS_SIGNATURE_VERIFICATION_FAILED';
  }
}