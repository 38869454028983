import { InsuranceProvider, Product, SUPPORTED_STATE } from './global';

export enum GENDER {
  MALE = 'male',
  FEMALE = 'female',
}

export enum SMOKING_HABIT {
  SMOKER = 'smoker',
  NONSMOKER = 'nonsmoker',
}

export interface TermRate {
  age: number;
  [healthRate: string]: number | string /* This rate should be yearly and will be divided by 12*/;
}

export interface MonthlyPricesByHealth {
  monthlyPriceFairlyHealthy?: string;
  monthlyPriceHealthy?: string;
  monthlyPriceSuperHealthy?: string;
}

export interface MonthlyPriceRange {
  monthlyPriceLowerRange: string;
  monthlyPriceUpperRange: string;
}

export enum FinalExpenseQuoteHealthLevel {
  superHealthy = 'Super Healthy',
  healthy = 'Healthy',
  fairlyHealthy = 'Fairly Healthy',
}

export enum TermInsuranceHealthLevel {
  high = 'high',
  low = 'low',
}

export enum SimpleRateHealthLevelHavenLife {
  best = 'best',
  better = 'better',
  average = 'average',
  tobacco = 'tobacco',
}

export enum TermRateHealthLevelHavenLife {
  standard = 'standard',
  selectPreferred = 'selectPreferred',
  ultraPreferred = 'ultraPreferred',
}

export enum TermInsuranceQuoteHealthLevelNationwideKey {
  nonTobaccoPreferedPlus = 'nonTobaccoPreferedPlus',
  tobaccoPrefered = 'tobaccoPrefered',
  tobaccoStandard = 'tobaccoStandard',
  nonTobaccoStandard = 'nonTobaccoStandard',
}

export enum TermInsuranceQuoteHealthLevelNationwide {
  nonTobaccoPreferedPlus = 'Preferred Plus Nontobacco',
  tobaccoPrefered = 'Preferred Tobacco',
  tobaccoStandard = 'Standard Tobacco',
  nonTobaccoStandard = 'Standard Nontobacco',
}

export enum TermRateHealthLevelPGL {
  PREFERRED_BEST = 'preferredBest',
  PREFERRED = 'preferred',
  STANDARD = 'standard',
}

export interface QuoteResults {
  medium?: string;
  high: string;
  low: string;
}

export enum INSURANCE_TERM {
  TERM5 = 'term5',
  TERM10 = 'term10',
  TERM15 = 'term15',
  TERM20 = 'term20',
  TERM25 = 'term25',
  TERM30 = 'term30',
}

export type RiskRates = {
  [smokingHabit in SMOKING_HABIT]: TermRate[];
};

export type TermQuoteTable = {
  [term in INSURANCE_TERM]?: {
    [coverage: string]: {
      [gender in GENDER]: RiskRates;
    };
  };
};

export type FlatTermQuoteTable = {
  age: number;
  term: INSURANCE_TERM;
  coverage: string;
  gender: GENDER;
  premium: string | number;
  risk: string;
}[];

export type GuaranteedFinalExpenseQuoteTable = {
  [gender in GENDER]: {
    [age: string]: Array<{
      unit: string; // unit 1
      premium: number;
      faceAmount: number;
    }>;
  };
};

export interface TermQuoteInputs {
  coverage: number;
  age: string;
  gender: GENDER;
  smoker: boolean;
  termYears: number;
  table: TermQuoteTable;
  provider: InsuranceProvider;
  state?: SUPPORTED_STATE;
  product: Product;
}

export interface QuoteInputs {
  coverage: number;
  age: string;
  gender: GENDER;
  smoker?: boolean;
  termYears?: number;
  table: ProductQuoteData;
  provider: InsuranceProvider;
  state?: SUPPORTED_STATE;
  product: Product;
}

export interface FinalExpenseQuoteInputs {
  coverage: number;
  age: string;
  gender: string;
  table: GuaranteedFinalExpenseQuoteTable;
}

export type Term5QuoteTable = TermQuoteTable['term5'];
export type Term10QuoteTable = TermQuoteTable['term10'];
export type Term15QuoteTable = TermQuoteTable['term15'];
export type Term20QuoteTable = TermQuoteTable['term20'];
export type Term25QuoteTable = TermQuoteTable['term25'];
export type Term30QuoteTable = TermQuoteTable['term30'];

export const NATIONWIDE_TERM_STEP = 10000;

export type ProductQuoteData = Partial<TermQuoteTable> | GuaranteedFinalExpenseQuoteTable;

export interface CachedProductQuote<T extends ProductQuoteData> {
  provider: InsuranceProvider;
  product: Product;
  state?: SUPPORTED_STATE;
  term?: INSURANCE_TERM;
  data: T;
}

export type CachedProductQuoteOptions = Omit<CachedProductQuote<any>, 'data'>;

export type CustomMonthlyPriceFormulaByProduct = {
  [product in Product]?: (coverage: number, product?: Product) => (rate: number) => number;
};

export type CustomMonthlyPriceFormulasByProviderByProduct = {
  [provider in InsuranceProvider]?: CustomMonthlyPriceFormulaByProduct;
};

export enum PGL_RATES_BAND {
  BAND1 = 'band1',
  BAND2 = 'band2',
}

export enum HAVENLIFE_TERM_RATES_BANDS {
  BAND1 = '250000',
  BAND2 = '500000',
  BAND3 = '1000000',
}

export enum HAVENLIFE_SIMPLE_RATES_BANDS {
  BAND1 = '25000',
  BAND2 = '100000',
  BAND3 = '250000',
  BAND4 = '400001',
  BAND5 = '500001',
  BAND6 = '750001',
}
