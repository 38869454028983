export enum STREAMING_ROOM {
  CALL_REVIEW = 'call_review',
  INCOMING_WARM_TRANSFER = 'incoming_warm_transfer',
  LEAD_ASSIGNEMENT = 'LeadAutoAssignment',
  LIVE_CALL_STATUS = 'liveCallStatus',
  ADVOCATE_STATUS = 'advocate_status',
}

export const AVAILABLE_STREAMING_ROOMS = Object.values(STREAMING_ROOM);

export enum STREAMING_MESSAGE {
  JOIN = 'join',
  LEAVE = 'leave',
  EVENT = 'event',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  CONNECT_ERROR = 'connect_error',
  NO_SUCH_ROOM = 'no_such_room',
  ROOM_ALREADY_JOINED = 'room_already_joined',
  ROOM_LEFT = 'room_left',
  ROOM_JOINED = 'room_joined',
  MISSING_PERMISSIONS = 'missing_permissions',
}
