import * as jose from 'jose';
import { DateTime } from 'luxon';

export const extractExpirationTimestampFromTokenInSeconds = (token: string): number => {
  const { exp } = jose.decodeJwt(token) as { exp: number }; // Unix timestamp (seconds)
  return exp;
};

export const isTokenExpired = (token: string): boolean => {
  const exp = extractExpirationTimestampFromTokenInSeconds(token);
  return DateTime.now() > DateTime.fromSeconds(exp);
};

export const verifyToken = async (
  token: string,
  JWKS: any,
  opts: { audience: string; issuer: string },
): Promise<boolean> => {
  try {
    await jose.jwtVerify(token, JWKS, opts);
    return true;
  } catch (e) {
    return false;
  }
};

export const getJwks = (url: string) => jose.createRemoteJWKSet(new URL(url));

export const decodeToken = jose.decodeJwt;

/*
For UI usage, for the crypto object, use window.crypto.
For Server side usage, use the embedded crypto module. crypto.webcrypto
*/
export const generateNonce = (
  crypto: { getRandomValues<T extends ArrayBufferView | null>(array: T): T } | null,
  length = 16,
) => {
  if (crypto) {
    const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz+/';
    let result = '';

    while (length > 0) {
      const bytes = new Uint8Array(16);
      const random = crypto.getRandomValues(bytes);

      random.forEach(function (c) {
        if (length == 0) {
          return;
        }
        if (c < charset.length) {
          result += charset[c];
          length--;
        }
      });
    }
    return result;
  }

  /* Fallback, it seems some browser (like facebook in app, do not ship the crypto module) */
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

const base64ToBytes = (base64: string) => {
  const binString = atob(base64);
  return Uint8Array.from(binString, (m) => m.codePointAt(0) as number);
};

function bytesToBase64(bytes: Uint8Array): string {
  const binString = Array.from(bytes, (x) => String.fromCodePoint(x)).join('');
  return btoa(binString);
}

export const encodePayloadToBase64 = (payload: object) => {
  return bytesToBase64(new TextEncoder().encode(JSON.stringify(payload)));
};

export const decodeBase64Payload = <T extends object>(base64: string): T => {
  return JSON.parse(new TextDecoder().decode(base64ToBytes(base64)));
};
