/*
export functional programming repo for o8 projects
For some insights: https://medium.com/free-code-camp/pipe-and-compose-in-javascript-5b04004ac937 and https://medium.com/javascript-scene/reduce-composing-software-fe22f0c39a1d
Type definition is extracting from Ramda: https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/ramda/index.d.ts
*/
import { compose } from './compose';
import { pipe } from './pipe';

const pick = <T extends object, K extends keyof T>(source: T, ...keys: K[]): Pick<T, K> => {
  return keys.reduce((acc, key) => {
    return { ...acc, [key]: source[key] };
  }, {} as Pick<T, K>);
};

const omit = <T extends object, K extends keyof T>(source: T, ...keys: K[]): Omit<T, K> => {
  const obj = { ...source };
  keys.forEach((k) => {
    delete obj[k];
  });
  return obj;
};

export const fp = {
  compose,
  pipe,
  pick,
  omit,
};
