import { isPlatformServer } from '@angular/common';
import { InjectOptions, Injectable, InjectionToken, Injector, PLATFORM_ID, ProviderToken } from '@angular/core';

export const WINDOW = new InjectionToken<Window>('window');
export const LOCAL_STORAGE = new InjectionToken<Storage>('localStorage');
export const APP_CONFIG = new InjectionToken<object>('APP_CONFIG');

const windowFactory = (platformId: object) => {
  if (isPlatformServer(platformId)) {
    return undefined;
  }
  return window;
};
const localStorageFactory = (platformId: object) => {
  if (isPlatformServer(platformId)) {
    return undefined;
  }
  return window.localStorage;
};

export const windowProvider = { provide: WINDOW, useFactory: windowFactory, deps: [PLATFORM_ID] };
export const localStorageProvider = { provide: LOCAL_STORAGE, useFactory: localStorageFactory, deps: [PLATFORM_ID] };

@Injectable({ providedIn: 'root' })
export class LazyInject {
  constructor(private injector: Injector) {}

  async get<T>(providerLoader: () => Promise<ProviderToken<T>>) {
    return this.injector.get(await providerLoader());
  }

  getSync<T>(
    token: ProviderToken<T>,
    notFoundValue?: undefined,
    options?: InjectOptions & {
      optional?: false;
    },
  ) {
    return this.injector.get(token, notFoundValue, options);
  }
}
