/* Checks if a given object or array is empty */
export function isEmpty(x: object | Array<any> | undefined | null): boolean {
  if (!x) {
    return true;
  }
  return Object.keys(x).length === 0;
}

export const isDevOrQaHostname = (hostname: string) => {
  const nonProdHostname = ['localhost', 'web.dev.origin8cares', 'web.uat.origin8cares'];
  const nonProdEndingHostname = ['.dev.origin8cares.com', '.uat.origin8cares.com'];
  return nonProdHostname.some((h) => hostname.startsWith(h)) || nonProdEndingHostname.some((h) => hostname.endsWith(h));
};

export const isProdHostname = (hostname: string) => !isDevOrQaHostname(hostname);

/*
Take an array of objects and return a new array with unique values based on a key
*/
export function filterDuplicatesByKey<T extends object>(array: Array<T>, key: keyof T): Array<T> {
  return [...new Map(array.map((item) => [item[key], item])).values()];
}

export const isArrayOfString = (value: unknown): boolean => {
  if (Array.isArray(value)) {
    return !value.some((e) => typeof e !== 'string');
  }
  return false;
};

export const stringToColor = (str: string): string => {
  {
    let hash = 4;
    if (!str) {
      throw new Error('String must be non-empty to get a color');
    }
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 255;
      const stringValue = '00' + value.toString(16);
      color += stringValue.substring(stringValue.length - 2);
    }
    return color;
  }
};
